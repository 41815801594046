import React from 'react';

import {Link} from 'react-router-dom'


import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';

export default function About() {
    //const {catName} = useParams();

  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"About Us"} /></Col>
            </Row>
          </div>
        </section>

        <section className='aboutvijaypath'>
            <div className='container'>
                <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="About Us"/>

                        <div>

                        <p></p>

<p>Support india is an online video streaming platform owned by transmitto development foundation . Support india is digital platforms where the people watch video. Shorts.agriculture video. Education video. Movie.web series. Movie. Live streaming news support india video - sharing platform that allows registered users to upload videos of their own as well as to watch videos postested by users. Support india is also focus the health of the  environment and planet and youth empowerment.Support india also involves Social activities .social activities means all activities associated associated with socialising with our  the our community and our country.</p>

                        </div>

                        
                    </Col>
                </Row>
            </div>
        </section>
    </>
  )
}
