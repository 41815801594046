import React from 'react';
import { useState } from 'react';
import { Link,NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa6";
import Offcanvas from 'react-bootstrap/Offcanvas';

import { IoMdSettings } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";
import { MdNoteAlt } from "react-icons/md";
import { FaUserLock } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";

import Categorydata from '../../data/category.json';
import playstoreicon from '../../images/googlePlay.svg'
import directicon from '../../images/direct-download.webp'


export default function Canvasmenu() {

   

    // ===offcanvas
    const [showCanvas, setShowCanvas] = useState(false);

    const handleCloseCanvas = () => setShowCanvas(false);
    const handleShowCanvas = () => setShowCanvas(true);



    return (
        <>
        <FaBars onClick={handleShowCanvas} />
         
            {/* ==offcanvas=== */}
            <Offcanvas show={showCanvas} onHide={handleCloseCanvas}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Categories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="ofmenu">
                        {
                            Categorydata.map((cat, index) => {
                                if (index > 5) {
                                    return (<li key={cat.id}><NavLink activeclassname="active" to={cat.link.toLowerCase()} title={cat.name} onClick={handleCloseCanvas}>{cat.name}</NavLink></li>)
                                }
                            })
                        }
                         <li><NavLink activeclassname="active" to={"coupon"}><BiSolidOffer/> Special Offer</NavLink></li>
                {/* <li><Link to={""}><IoMdSettings /> Settings</Link></li>
                <li><Link to={""}><FaQuestionCircle /> FAQ</Link></li> */}
                
                <li><NavLink activeclassname="active" to={"about"}><MdNoteAlt /> About Us</NavLink></li>
                <li><NavLink activeclassname="active" to={"terms"}><MdNoteAlt /> Terms of Service</NavLink></li>
                <li><NavLink activeclassname="active" to={"privacy"}><FaUserLock /> Privacy Policy</NavLink></li>
                <li><NavLink activeclassname="active" to={"contact"}> Contact Us</NavLink></li>
                <li><NavLink activeclassname="active" to={"cancelation-policy"}> Cancelation Policy</NavLink></li>
                <li><NavLink activeclassname="active" to={"refund-policy"}> Refund Policy</NavLink></li>
                <li><Link to="tel:03369066400"><IoCall />Call us 03369066400</Link></li>
                <li><Link to="https://play.google.com/apps/testing/com.supportindiaott" target='_blank'><img src={playstoreicon} alt="Google Playstore" style={{maxWidth:"100%"}}/></Link></li>
                              <li><Link to="/appdownload"><img src={directicon} alt="Direct Download" style={{maxWidth:"128px"}}/></Link></li>
                {/* <li><Link to={""}><MdLogout /> Log Out</Link></li> */}

                    </ul>

                    <div className="version">Support india is an online video streaming platform owned by <b style={{textTransform:"uppercase"}}>transmitto development foundation</b>.</div>

                    <div className="version">Version: <span>1.0</span></div>
                </Offcanvas.Body>
            </Offcanvas>
            {/* ==offcanvas=== */}

        </>
    );
}
