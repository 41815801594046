import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { BsThreeDots } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { CiFlag1 } from "react-icons/ci";

export default function ReportOption() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  return (
    <>
        <div  className={`hBtn optiondropBtn ${isOpen === true ? 'active' : ''}`} onClick={handleToggle} ref={dropdownRef}>
                                <BsThreeDots  />
                                {isOpen && (
                                    <ul className='optiondropDown'>
                                        <li><CiFlag1/> Report</li>
                                    </ul>
                                )}
                            </div>
    </>
  )
}
