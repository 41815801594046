import React from 'react';
import '../headerSec/header.css';
import logo1 from '../../images/loader1.gif';
import logo2 from '../../images/loader2.gif';
import darkButton from '../../images/dark-theme.svg';
import { useState, useEffect, useRef } from 'react';

import Modal from 'react-bootstrap/Modal';


import Categories from './Categories';
import Tags from './Tags';


import { IoSearch } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";


import { SlHome } from "react-icons/sl";
import { LuBellRing } from "react-icons/lu";
import { TfiWallet } from "react-icons/tfi";




import Login from '../login/Login';
import { Link } from 'react-router-dom';

import Canvasmenu from './Canvasmenu';

export default function Header() {

  // =====search bar
  const [isActive, setIsActive] = useState(false);

  const toggleClass = (event) => {
    setIsActive(true);
    event.stopPropagation();
  };

  const closeSearch = (event) => {
    setIsActive(false);
    event.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const searchWrapper = document.getElementById('search-wrapper');
      if (!searchWrapper.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);




  // ====login-modal
  const [modalShow, setModalShow] = useState(false);


  // ====dark mode 
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const body = document.body;
    if (darkMode) {
      body.classList.add('dark-mode');
    } else {
      body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  // ====loader
  const loaderRef = useRef(null);

  useEffect(() => {
    const hideLoader = () => {
      if (loaderRef.current) {
        setTimeout(() => {
          loaderRef.current.style.display = 'none';
        }, 1000);
      }
    };



    //window.addEventListener('load', hideLoader);


    if (document.readyState === 'complete') {
      hideLoader();
    } else {
      window.addEventListener('load', hideLoader);
    }


    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('load', hideLoader);
    };
  }, []);



  // ===header hooks
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // Check if scrolled past 100px
      if (scrollTop >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      // Check scroll direction
      if (scrollTop > lastScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div id="loader" ref={loaderRef}>
        <img src={logo1} alt="" className="loader1" />
        <img src={logo2} alt="" className="loader2" />
      </div>

      <button id="toggleButton" onClick={toggleDarkMode} className={darkMode ? 'toggle-button darkBtn' : 'toggle-button'}>
        <img src={darkButton} alt="" />
      </button>


      <header className={`header_sec ${isScrolled ? 'scrolled' : ''} ${scrollDirection === 'down' ? 'scrolled-down' : ''}`} id="header_sec">
        <div className="headerbg" id="headerbg"></div>
        <div id="search-wrapper" className={isActive ? 'search-wrapper show-search' : 'search-wrapper'}
          onClick={(event) => event.stopPropagation()}>
          <form action="/results">
            <div className="inputWrapper">
              <input type="text" placeholder="Search for Movies, Shows, Channels etc...." className="form-control" name="search_query" />
              <div className="microphoneBTn"><FaMicrophone /></div>
            </div>
          </form>
          <Link className="search-close" id="search-close" onClick={closeSearch} ><IoClose /></Link>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="hambergerBtn" variant="primary"><Canvasmenu /></div>
            {/* <div className="hambergerBtn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">H</div> */}

            <Link className="navbar-brand" to={"/home"}>
              <div className="navImg">
                <img src={logo1} alt="" className="loader1" />
                <img src={logo2} alt="" className="loader2" />
              </div>
              Support India
            </Link>

            <div className="searchbtn mobileSearch" id="searchbtn1" title="Search" onClick={toggleClass}><IoSearch /></div>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa-solid fa-bars"></i>
              </button> */}
            <div className="navbar_wrapper" id="navbarSupportedContent">
              <Categories />
              <div className="rightbar">
                <div className="specialOffer"><Link to={"/appdownload"} style={{ color: "var(--red)", textDecoration: "none" }}> Download App</Link></div>

                <div className="searchbtn" id="searchbtn" title="Search" onClick={toggleClass}><IoSearch /></div>

                <div className="loginbtn" title="Login" onClick={() => setModalShow(true)}>
                  <div className="loginicon"><FaRegUser /></div>
                  <div className="logname">Log In</div>
                </div>
                <Link to={"/subscribe"} className="btn primary-btn subscribeBtn" title="Subscribe">Subscribe</Link>
              </div>
            </div>
          </div>
        </nav>
        <div className="categoriesSlide_sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <Tags />
              </div>
            </div>
          </div>
        </div>
      </header>




      {/* login modal start */}


      <Modal

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <Login />
        </Modal.Body>

      </Modal>




      {/* login modal close */}

      {/* mobile footer menu */}

      <section className="mFooter">
        <div className="container-fluid">
          <div className="mfooter_wrapper">
            <ul>
              <li><Link to="/" className="f-nav active-menu"><SlHome /><span>Home</span></Link></li>

              <li><Link to="/appdownload" className="f-nav"><BiSolidOffer /><span>App</span></Link></li>

              <li><Link to="/yodha" className="f-nav"><LuBellRing /><span>Yodha</span></Link></li>

              <li><Link to="/category/reels" className="f-nav"><TfiWallet /><span>Reels</span></Link></li>

              <li><Link to="#" className="f-nav" onClick={() => setModalShow(true)}><FaRegUser /><span>Account</span></Link></li>
            </ul>
          </div>
        </div>
      </section>
      {/* mobile footer menu close */}
    </>
  )
}
