import React from 'react';

import {Link} from 'react-router-dom'


import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';

export default function Refund() {
    //const {catName} = useParams();

  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"REFUND POLICY"} /></Col>
            </Row>
          </div>
        </section>

        <section className='aboutvijaypath'>
            <div className='container'>
                <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="REFUND POLICY"/>

                        <div>

                        <p><b>Can I get a refund from support india ?</b></p>

<p>
NO. Support india does not offer refunds or credits for partially used billing periods, unless required
by applicable law. As a policy, support india does not offer any partial or full refunds. In case if users
require any assistance with regards to payments or have any queries/concerns, they can contact
us toll fee no - : 03369066400</p>

                        </div>

                        
                    </Col>
                </Row>
            </div>
        </section>
    </>
  )
}
