import React from 'react';
import { useState, useEffect } from 'react';
import footLogo from '../../images/loader1.gif';
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import playstoreicon from '../../images/googlePlay.svg'
import directicon from '../../images/direct-download.webp'


export default function Footer() {
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        const handleMenuClick = (event) => {
            setActiveMenu(event.target);
        };

        const navElements = document.querySelectorAll('.f-nav');
        navElements.forEach((el) => el.addEventListener('click', handleMenuClick));

        return () => {
            navElements.forEach((el) => el.removeEventListener('click', handleMenuClick));
        };
    }, []);

    useEffect(() => {
        const navElements = document.querySelectorAll('.f-nav');
        navElements.forEach((el) => el.classList.remove('active-menu'));
        if (activeMenu) {
            activeMenu.classList.add('active-menu');
        }
    }, [activeMenu]);
  return (
    <>
        {/* =====fooeter start===== */}
        <div className="footerSec">
        <div className="footerMain">
            <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6 col-lg-2">
                      <div className="brandbar">
                          <div className="fBrand"><img src={footLogo} alt="Support India"/>Support India</div>
                          <p>Support india is an online video streaming platform owned by <b style={{textTransform:"uppercase"}}>transmitto development foundation</b> .</p>
  
                          <ul className="sharebar">
                              <li><Link to=""><FaFacebookF/></Link></li>
                              <li><Link to=""><FaYoutube/></Link></li>
                              <li><Link to=""><FaXTwitter/></Link></li>
                              <li><Link to=""><FaInstagram /></Link></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">Company</b>
                          <ul className="fMenu">
                              <li><Link to="/about">About Us</Link></li>
                              <li><Link to="/terms">Terms of Service</Link></li>
                              <li><Link to="/privacy">Privacy Policy</Link></li>
                             
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">Customer</b>
                          <ul className="fMenu">
                              <li><Link to="/cancelation-policy">Cancelation Policy</Link></li>
                              <li><Link to="/refund-policy">Refund Policy</Link></li>
                            
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">Connect</b>
                          <ul className="fMenu">
                              <li><Link to="/contact">Contact Us</Link></li>
                              <li><Link to="tel:03369066400">Call us 03369066400</Link></li>
                          </ul>
                      </div>
                  </div>
                  
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                        <b className="footerTitle">Download the App</b>
                      
                      <ul className="fMenu">
                              <li><Link to="https://play.google.com/apps/testing/com.supportindiaott" target='_blank'><img src={playstoreicon} alt="Google Playstore" style={{maxWidth:"100%"}}/></Link></li>
                              <li><Link to="/appdownload"><img src={directicon} alt="Direct Download" style={{maxWidth:"100%"}}/></Link></li>
                          </ul>
                      
                      
                      
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                      
                          
                      </div>
                  </div>
                </div>
          </div>
        </div>
        <div className="copyright">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <small>Copyright © 2024 SupportIndia</small>
                    </div>
                </div>
            </div>
        </div>
      </div>
        {/* =====fooeter start===== */}

     
    </>
  )
}
